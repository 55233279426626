import React, { useEffect, useState } from "react"

import { useCheckout } from "../../../../hooks/useCheckout"
import { useFunctions } from "../../../../hooks/useFunctions"
import { useLanguage } from "../../../../hooks/useLanguage"

export const withAccountGuestForm = Component => ({ name = `AccountGuestForm` }) => {
  const [acceptsMarketing, setAcceptsMarketing] = useState(``)
  const [email, setEmail] = useState(``)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  const { checkout, checkoutUrl, updateEmail } = useCheckout()
  const { customerSubscribe, checkoutMultipass } = useFunctions()

  const handleSubmit = async event => {
    event.preventDefault()
    setError(null)

    try {
      if (email) {
        setLoading(true)

        if (checkout?.email !== email) {
          await updateEmail(email)
        }

        if (acceptsMarketing) {
          await customerSubscribe(email)
        }

        setLoading(false)
        // window.location = checkoutUrl
        await checkoutMultipass(email, checkout.id, checkoutUrl)
      } else {
        throw new Error(`Please enter a valid email address`)
      }
    } catch (err) {
      setError(err)
      setLoading(false)
    }
  }

  const handleChange = ({ target: { value } }) => {
    setEmail(value)
  }

  const handleSubscribe = () => {
    setAcceptsMarketing(acceptsMarketing => !acceptsMarketing)
  }

  useEffect(() => {
    if (!email && checkout?.email) {
      setEmail(checkout.email)
    }
  }, [checkout])

  const invalid = !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    String(email).toLowerCase()
  )

  const locales = {
    ...useLanguage(`account`),
    ...useLanguage(`cart`),
    ...useLanguage(`form`),
  }

  Component.displayName = name
  return (
    <Component
      acceptsMarketing={acceptsMarketing}
      email={email}
      error={error}
      invalid={invalid}
      handleChange={handleChange}
      handleSubscribe={handleSubscribe}
      handleSubmit={handleSubmit}
      locales={locales}
      loading={loading}
    />
  )
}
